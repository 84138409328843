console.log('It\'s working!', window.location.pathname)

// redirect paths to hashes (e.g. preview from panel)
if (window.location.pathname.length > 1) {
  window.location.href = window.location.origin + '#' + window.location.pathname
}

const FOLLOWER_TEXT = {
  NAV_OPEN: '', //'open',
  NEXT_SLIDE: 'click for more'
}

function setFollowerText(text) {
  $('#info').text(text)
}

let currentPage;

var myElement = document.getElementById('content__wrapper');
if (myElement) {
  var mc = new Hammer(myElement);
  //mc.get('swipe').set({ direction: Hammer.DIRECTION_ALL }); // by default, it only adds horizontal recognizers
  // listen to events...
  mc.on("swipeleft swiperight swipeup swipedown tap", function (ev) {
    // ignore taps on content <a>s
    if (ev.type == 'tap' && $(ev.target).is('a')) {
      console.log("content clicked..")
      return false
    }

    const direction = ev.type == 'swiperight' || ev.type == 'swipedown' ? '-1' : '+1';
    setContentSlide(direction)
  });
}

// check if is mobile breakpoint
let isMobile = false;
if ($('#burger').css('display') != 'none') {
  isMobile = true
  $('body').addClass('is-mobile')
}

// helpers
function toggleEditionsMenu(state, loadContent = false) {
  if (state) {
    $('body').addClass('curated-editions__active');
  }
  else {
    $('body').removeClass('curated-editions__active');
  }

  if (loadContent) {
    window.location.hash = '/curated-editions'
  }
}

// convert all regular links to anchor links
$('a').each(function (i) {
  const url = $(this).attr('href')
  const url2hash = url
    .replace(window.location.origin, '#') // urls to hashes
    .replace('#/media', window.location.origin + '/media') // relink file downloads
  $(this).attr('href', url2hash)
})

// initial & detect hash change
// inform the menus to highlight the right thing
let calculatedHeights = false;
function locationHashChanged() {

  console.log("hash changed")

  //const target = location.hash.replace('#/', '').replace(/(?<!\#)\//, "\\/") || 'home'
  currentPage = location.hash.replace(/\//g, "\\/").replace('#\\/', '') || 'home'
  console.log("Navigation change --> ", currentPage);

  // reveal specific content
  setContentSlide(0);
  if(!calculatedHeights) calculateContentHeights();
  showAndHideSiblings(`#content__container .content__page.uid__${currentPage}`)

  // highlight menu
  $(`.menu__point, .menu__point a`).removeClass('active');
  $(`a.uid__${currentPage}`).addClass('active').parent('.menu__point').addClass('active')

  // make sure editions menu is closed when home is navigated
  if(currentPage=='home') toggleEditionsMenu(false)

  // check if edition is displayed - set color theme
  if ($(`.menu__point.active`).attr('style')?.length) {
    console.log($(`.menu__point.active`).css('background-color'))
    const bgColor = $(`.menu__point.active`).css('background-color')
    $('body').css('background-color', bgColor)
  }
  else {
    $('body').css('background-color', '')
  }

  // adjust mouse follower
  // overwrite hover()
  if ($('.menu__point.active').hasClass('has-slides')) {
    setFollowerText(FOLLOWER_TEXT.NEXT_SLIDE);
  }

  // check if has slides?
  if ($(`#content__container .content__page.uid__${currentPage} .content__slide`).length > 1) {
    $('body').addClass('has-slides')
  }
  else {
    $('body').removeClass('has-slides')
  }

}
window.onhashchange = locationHashChanged;
locationHashChanged()


function fadeInGlobalWrapper() {
  $("#wrapper").addClass('active');
}

// dir can be slideNumber or direction '+1' or '-1'
function setContentSlide(slideNr = '+1') {
  //console.log("got slideNr ", slideNr)
  if (!currentPage.includes('\\/')) {
    currentPage = currentPage.replace(/\//g, "\\/")
  }

  const $targetContent = $(`#content__container .content__page.uid__${currentPage}`)
  const $slides = $targetContent.find('.content__slide')

  const current = $targetContent.find('.content__slide.active').removeClass('active').index()
  const next = (typeof slideNr === 'string') ? (current + parseInt(slideNr)) % ($slides.length) : slideNr;
  $slides.eq(next).addClass('active')

  // find any indicators
  $(`.uid__${currentPage}`).each(function(i) {
    $(this).find('.slide-indicator-dot').removeClass('active')
    $(this).find('.slide-indicator-dot').eq(next).addClass('active')
  })

  //console.log("next slide!", next)
}

function menuAction(action) {
  console.log("menu action!!!", action)
  if (action == '!open-editions') {
    toggleEditionsMenu(true)
    // follow link on open menu
    if (!$('body').hasClass('is-mobile')) {
      window.location.hash = '/curated-editions'
    }
  }
  else if (action == '!close-editions') toggleEditionsMenu(false)
  else console.error('unknown menu action')


}

function prepareNavigation() {

  // use all menu links that start with #/ to display specific content
  $('.menu__point').on('click', function (e) {

    // first check if the menu bar got clicked -> default to e.g. first link
    // or a direct link (relevant for editions and contact bar)
    const $clicked = $(e.target)
    const parentClicked = !$(e.target).is("a")

    let $link = parentClicked ? $(this).find('a:first-of-type') : $clicked
    // catch special case CURATED EDITIONS parent hover (first is hidden)
    if (parentClicked && $(this).hasClass('uid__curated-editions') && !$('body').hasClass('curated-editions__active')) {
      $link = $(this).find("a[href^='!']").eq(0)
    }

    // calculate target
    let target;
    let $targetContent;

    // check for commands (starts with ! instead of #)
    if ($link.attr('href').includes('!')) {
      target = $link.attr('href')
      menuAction(target)
      return false; // important: let browser not follow this link
    }
    else {
      // regular link
      target = $link.attr('href').replace(/#\/(\w+)/g, '$1').replace('#', '')
      $targetContent = $(`#content__container .content__page.uid__${target.replace('/', "\\/")}`)
      // follow on mobile
      $('body.mobile-menu__open').removeClass('mobile-menu__open')
    }

    console.log(window.location.hash, '#/' + target)
    currentPage = target // cache current page

    if (window.location.hash != '#/' + target) {
      window.location.hash = '/' + target
    }
    else { // same page
      if ($targetContent.find('.content__slide').length > 1) {
        setContentSlide('+1')
      }
      else if (target == 'curated-editions') {
        locationHashChanged();
        console.log("reopen edition menu..")
      }
      else {
        console.log("same page... do nothing :)")
      }
    }
  })


  // JS hover becasue we want to lighlight parent and siblings as well
  $('.menu__point').hover(function () {
    $(this).addClass('hovered')
    //$('#info').text('open')
    if ($(this).hasClass('active') && $(this).hasClass('has-slides')) {
      setFollowerText(FOLLOWER_TEXT.NEXT_SLIDE)
      return true
    }
    else {
      setFollowerText(FOLLOWER_TEXT.NAV_OPEN)
    }
    //console.log("hovered menu entry active?", $(this).hasClass('active'), " slides?", $(this).hasClass('has-slides'))
  }, function () {
    $(this).removeClass('hovered')
  })

  // custom hover on menu bars with MULTIPLE direct links
  $('.menu__point > .direkt-link').hover(function () {
     $(this).addClass('hovered')
     $(this).parent('.menu__point').addClass('hovered-direkt-link')
  }, function () {
     $(this).removeClass('hovered')
     $(this).parent('.menu__point').removeClass('hovered-direkt-link')
  })

  $('a[href="#close-editions"]').on('click', function (e) {
    $('body').removeClass('curated-editions__active')
    window.location.hash = '#/curated-editions'
  })

}

function showAndHideSiblings(target) {
  //target = target.replace('/', "\\/")
  $(target).fadeIn('slow').siblings().hide();
  //$(target).addClass('active').siblings().removeClass('active')
}



// smooth fade in
document.onreadystatechange = function (event) {
  if (document.readyState === "complete") {
    //calculateContentHeights();
    prepareNavigation();
    fadeInGlobalWrapper();

    // setzo burgerssss
    $('#burger').on('click', function () {
      $('body').toggleClass('mobile-menu__open')
    })
    $('body.menu__open :not(.menu__point)').on('click', function () {
      console.log("huhu!")
      $('body').removeClass('mobile-menu__open')
    })
  }
};

function calculateContentHeights() {
      // find max height slide (absolute) to set parent (relative) to that - keep the float
    // watch out – its hidden so it would give height: 0px
    $('.content__slides-wrapper').each(function (index) {
      //console.log("content slides found...")
      let maxHeight = 0;
      $(this).find('.content__slide').each((index, el) =>      {
        const height = $(el).outerHeight(true);
        maxHeight = height > maxHeight ? Math.ceil(height) : maxHeight
      })
      $(this).css('flex-basis', maxHeight)
      //console.log("set max height to outer (relative) container. the flow is back!!", maxHeight, $(this).parent().attr('class'))
    });
  calculatedHeights = true;
}

// readjust content boxes (they are absolute... so it's needed to keep the spacing in flex)
var resizeTimer;
$(window).on('resize', function(e) {
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(function() {
    calculateContentHeights();
    console.log("recalculated content heights")
  }, 250);

});




// attach mouse follower
$(document).on('mousemove', function(e){
    $('#info').css({
       left:  e.pageX+0,
       top:   e.pageY+0
    });
});
